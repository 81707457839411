import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import windowGlobal from '../constants/window';
export default function ContactForm() {
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    windowGlobal.addEventListener('click', (e) => {
        if (e.target === document.querySelector('.modal')) {
            close();
        }
    })
    function submit(e) {
        e.preventDefault();
        let xhr = new XMLHttpRequest();
        let form = e.target;
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            setError('');
            if (xhr.status === 200) {
                form.reset();
                setShowModal(true)
            } else {
                setError('Oops! something wrong happened. Please Try again later')
            }
        };
        xhr.send(new FormData(form));
    }
    function close() {
        setShowModal(false);
    }
    return (
        <div className="wrapper top">
            <div className="socials">
            </div>
            <div className="contact-form">
                <h3 className="text-primary">Let's work together</h3>
                <form id="form" onSubmit={submit} action="https://formspree.io/mlepglge" method="POST">
                    <div id="error">{error}</div>
                    <input type="hidden" name="_next" value="http://127.0.0.1:5500/thanks.html" />
                    <label>
                        Email Address *
                        <input required type="text" name="email" />
                    </label>
                    <label>
                        Name *
                        <input required type="text" name="name" />
                    </label>
                    <label>
                        Message *
                        <textarea required name="message"></textarea>
                    </label>
                    <button type="submit" className="btn btn-full">
                        Send Message
                    </button>
                </form>
            </div>
            <div id="confirmation-modal" style={{ display: showModal ? 'block' : 'none' }} className="modal">
                <div className="modal-wrapper">
                    <div className="modal-container">
                        <FaTimes className="modal-close" onClick={close}></FaTimes>
                        <div className="modal-body">
                            <div className="icon"></div>
                            <h4 className="modal-body-title" >Mail Sent!</h4>
                            <p className="modal-body-text">Thank you for contacting me. I will get back to you pretty soon.</p>
                            <button id="modal-body-button" onClick={close} className="btn btn-full close">Okay</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
