import React from 'react'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
export default () => {
    return (
        <Layout>
            <div className="section-container top" id="contact">
                <SEO title="Contact"  description="Contact page for future projects"/>
                <ContactForm />
            </div>
        </Layout>
    )
}
